<template>
  <b-button :loading="isLoading" class="bg-white border-2 border-indigo-400" :class="isPulled" :icon-left="icon"
    @click.stop="handleOnClick" :disabled="!isLoading && disabled">
    <slot></slot>
  </b-button>
</template>

<script>
export default {
  name: 'SButton',
  props: {
    type: {
      type: String,
      default: 'is-primary',
    },
    once: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    pulled: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onClickFn: {
      type: Function,
      // eslint-disable-next-line no-console
      default: () => console.log('onClickFn'),
    },
    paramsFn: {
      type: Function,
      default: () => { },
    },
  },
  data() {
    return {
      isLoading: false,
      counter: 0,
    };
  },
  computed: {
    isPulled() {
      return this.pulled ? `is-pulled-${this.pulled}` : '';
    },
  },
  mounted() { },
  methods: {
    async handleOnClick() {
      if (this.once && this.counter !== 0) {
        this.isDisabled = true;
        return;
      }
      this.counter += 1;
      this.isLoading = true;

      try {
        await this.onClickFn(this.paramsFn());
      } catch (err) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Hubo un error, revisa los datos y volvé a probar',
          position: 'is-bottom',
          type: 'is-danger',
        });
      }
      this.isLoading = false;
      if (this.once) {
        this.isDisabled = true;
      }
    },
  },
  watch: {},
};
</script>
