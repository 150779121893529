<template>
  <section>
    <b-modal :active="active" :has-modal-card="true" :can-cancel="['escape', 'outside']" @close="$emit('dismiss')">
      <div class="border-2 border-indigo-400 rounded-lg modal-card">
        <section class="modal-card-body">
          <b-field label="Nombre">
            <b-input data-cy="name" v-model="partner.name" type="text"></b-input>
          </b-field>
          <b-field label="Username">
            <b-input data-cy="username" ref="nameInput" v-model="partner.username"></b-input>
          </b-field>

          <b-field label="Email">
            <b-input data-cy="email" ref="nameInput" v-model="partner.email"></b-input>
          </b-field>
          <b-checkbox data-cy="wholesaler" v-model="partner.wholesaler" class="is-pulled-right" :rounded="true"
            :outlined="true" type="is-success" :left-label="true"></b-checkbox>
          <b-field label="Mayorista"> </b-field>
          <b-field label="Telefono">
            <PhoneInput v-model="partner.phone" :phone="partner.phone"></PhoneInput>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <button class="button" @click="$emit('dismiss')">Cancelar</button>
          <Button data-cy="create-partner" type="is-success" :onClickFn="handleAddPartner">Agregar</Button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import ApiService from '../../services/ApiService';
import { defaultData } from '../../schemas/partner';
import { disconnectedCopy } from '../../util/helpers';
import PhoneInput from '../PhoneInput.vue';

const { UserService } = ApiService;

export default {
  name: 'NewPartner',
  props: {
    active: Boolean,
  },
  components: {
    PhoneInput,
  },
  data() {
    return {
      partner: disconnectedCopy(defaultData),
    };
  },
  watch: {},
  computed: {},
  methods: {
    async handleAddPartner() {
      const payload = this.partner;
      await UserService.create(payload);
      this.partner = disconnectedCopy(defaultData);
      this.$emit('dismiss');
    },
  },
};
</script>
