<template>
  <div>
    <Section>
      <div class="columns">
        <div class="column is-3 is-offset-9">
          <b-datepicker :mobile-native="false" position="is-bottom-left" placeholder="Fecha de entrega" locale="es-ES"
            v-model="dateFilter" range :nearby-selectable-month-days="true" @input="handleChangeDates">
            <button class="button is-warning" @click="
              dateFilter = [new Date(), new Date()];
            handleChangeDates();
            ">
              <b-icon icon="calendar-today"></b-icon>
              <span>Hoy</span>
            </button>

            <button class="mt-1 button is-danger is-pulled-right" @click="dateFilter = []">
              <b-icon icon="close"></b-icon>
              <span>Borrar filtro</span>
            </button>
          </b-datepicker>
        </div>
      </div>
      <!-- <SearchBar
        :store="dispatchStore"
        :arrayParams="[this.orderOptions, this.buyOptions]"
        placeholder="✨ Buscá clientes y direcciones"
      ></SearchBar> -->
      <div v-if="isFetched.roadmaps">
        <div class="flex flex-row-reverse gap-2">
          <b-button class="is-primary is-light" @click="toggleCompact()">
            <b-icon :icon="compact ? 'card-text-outline' : 'table-column'"></b-icon>
          </b-button>
          <b-button class="text-white bg-purple-500 rounded " @click="handleCreateRoadmap(new Date().toISOString())">
            Generar nueva hoja de ruta
          </b-button>
        </div>
        <div v-for="day of roadmapDays" :key="day" :id="day">
          <div @click="collapseDay(day)" class="relative p-2 px-4 my-2 rounded-lg shadow cursor-pointer bg-purple-50">
            <button class="absolute top-2 right-5 no-focus" type="is-text" size="is-small">
              <b-icon :icon="collapsedDays.includes(day) ? 'chevron-up' : 'chevron-down'
                " />
            </button>
            <!-- <b-checkbox
              v-if="operationPermission && !compact"
              class="absolute p-0 -right-3 top-10"
              v-model="selectedDays"
              :native-value="day"
              type="is-primary"
              size="is-large"
              @click.prevent
              @input="handleSelectDay(day, index)"
            ></b-checkbox> -->

            <div class="grid items-center">
              <div class="">
                <p class="text-lg font-bold text-indigo-600">
                  {{ day | completeDate }}
                </p>
                <p class="text-sm">
                  {{ day | shortDate }}
                </p>

                <p class="text-sm">
                  Hojas de ruta:
                  <span class="font-bold">
                    {{ getRoadmapsByDay(day).length }}</span>
                </p>
                <p class="text-sm">
                  Envíos:
                  <span class="font-bold">
                    {{ getDispatchByDay(day).length }}</span>
                </p>
                <!-- <b-progress
                  :type="
                    getDeliveredDispatchByDay(day).length ===
                    getDispatchByDay(day).length
                      ? 'is-success'
                      : 'is-warning'
                  "
                  :value="getDeliveredDispatchByDay(day).length"
                  size="is-medium"
                  :max="getDispatchByDay(day).length"
                  show-value
                  class="w-64 my-1"
                >
                  <span
                    v-if="
                      getDeliveredDispatchByDay(day).length !==
                        getDispatchByDay(day).length
                    "
                    >{{ getDeliveredDispatchByDay(day).length }} entregado{{
                      getDeliveredDispatchByDay(day).length > 1 ? 's' : ''
                    }}
                    de {{ getDispatchByDay(day).length }} envíos</span
                  >
                  <span
                    v-if="
                      getDispatchByDay(day).length &&
                        getDeliveredDispatchByDay(day).length ===
                          getDispatchByDay(day).length
                    "
                    >Todos los envíos fueron entregados</span
                  >
                  <span v-if="!getDispatchByDay(day).length"
                    >No hay envíos para este día</span
                  >
                </b-progress>
                <p class="text-sm">
                  Total de Logística:
                  <span class="font-bold">
                    {{ logisticByDay(day) | currency(0) }}</span
                  >
                </p> -->
              </div>
            </div>
          </div>
          <!-- <button
            v-show="!collapsedDays.includes(day)"
            class="w-full p-2 mt-2 text-white bg-purple-500 rounded"
            @click="handleCreateRoadmap(day)"
          >
            Generar nueva hoja de ruta
          </button> -->
          <Layout v-show="!collapsedDays.includes(day)">
            <List :loading="isLoading.dispatchs">
              <div v-for="(roadmap, index) of getRoadmapsByDay(day)" :key="roadmap._id" class="relative">
                <div class="absolute flex flex-col gap-2 cursor-pointer top-2 right-5 no-focus place-items-center">
                  <b-button @click="handleDisableRoadmap(roadmap)" type="is-danger is-light">
                    <b-icon icon="trash-can" />
                  </b-button>
                  <ExportDeliveryRoute v-if="roadmap.dispatchs.length" :elements="roadmap.dispatchs">
                  </ExportDeliveryRoute>
                </div>

                <div class="absolute w-5 h-full rounded" :class="[`bg-cake-${index}`]"></div>
                <div class="flex flex-col w-full rounded" :class="[`bg-cake-${index}`]">
                  <div class="my-2 ml-5">
                    <p class="font-bold">Hoja de ruta # {{ roadmap.number }}</p>
                    <p>
                      <span class="font-bold">Repartidor:</span>
                      {{ roadmap.driver.name }}
                    </p>
                    <p>
                      <span class="font-bold">Notas:</span>
                      {{ roadmap.notes }}
                    </p>
                    <p>
                      <span class="font-bold">Envíos:</span>
                      {{ roadmap.dispatchs.length }}
                    </p>
                  </div>
                </div>
                <Draggable handle=".handle" :animation="200" v-model="roadmap.dispatchs" :group="`roadmaps_${day}`"
                  @change="(event) => changeDay(event, roadmap)" v-if="compact">
                  <div v-for="dispatch in roadmap.dispatchs" :key="dispatch._id"
                    class="flex flex-col p-1 mt-1 ml-5 text-xs shadow handle">
                    <span><span class="font-bold">
                        {{ dispatch.number }} -
                        {{
                          (dispatch.customer && dispatch.customer.name) ||
                          (dispatch.provider && dispatch.provider.name)
                        }}
                      </span>
                      -
                      {{
                        (dispatch.delivery &&
                          formatAddress(dispatch.delivery.address)) ||
                        (dispatch.provider &&
                          formatAddress(dispatch.provider.address))
                      }}</span>
                  </div>
                </Draggable>
                <div v-if="!compact">
                  <div v-for="dispatch in roadmap.dispatchs" :key="dispatch._id">
                    <OrderCard v-if="isOrder(dispatch)" :order="dispatch" :operationPermission="true"
                      :isDraggable="false" :isCheckable="false" :compact="compact" :showInstance="true"
                      :customStore="roadmapStore" customStoreKey="roadmaps" :componentClass="['ml-5 my-2']"></OrderCard>
                    <BuyCard v-if="isBuy(dispatch)" :buy="dispatch" :operationPermission="true" :isDraggable="false"
                      :isCheckable="false" :compact="compact" :showInstance="true" :customStore="roadmapStore"
                      customStoreKey="roadmaps" :componentClass="['ml-5 my-2']"></BuyCard>
                  </div>
                </div>
              </div>
            </List>
          </Layout>
        </div>
      </div>

      <OrderActions :selected="selected" :operationPermission="operationPermission" @deselectAll="handleDeselectAll"
        :actions="{
          invoice: false,
          deliveryRoute: true,
          export: false,
          prepare: false,
        }"></OrderActions>
      <NewRoadmapModal :active="isNewRoadmapModalActive" :date="dayToCreateRoadmap"
        @dismiss="isNewRoadmapModalActive = false"></NewRoadmapModal>
    </Section>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import { mapState } from 'pinia';
import {
  startOfDay, endOfDay, addDays, isSameDay,
} from 'date-fns';
import NewRoadmapModal from '../components/NewRoadmapModal.vue';
import Section from '../components/Section.vue';
// import { BUY_STATES } from '../util/constants';
import {
  isOrder, isBuy, sum, formatAddress,
} from '../util/helpers';

/* eslint-disable import/prefer-default-export */
import ExportDeliveryRoute from '../components/Order/ExportDeliveryRoute.vue';
import Layout from '../components/Layout.vue';
import OrderActions from '../components/Order/OrderActions.vue';
// import SearchBar from '../components/SearchBar.vue';
import List from '../components/List.vue';
import OrderCard from '../components/Order/OrderCard.vue';
import BuyCard from '../components/Buy/BuyCard.vue';
import useUserStore from '../store/user';
import useRoadmapStore from '../store/roadmap';
import useDispatchStore from '../store/dispatch';

export default {
  name: 'Prepare',
  components: {
    Draggable,
    OrderActions,
    // SearchBar,
    ExportDeliveryRoute,
    Layout,
    List,
    OrderCard,
    BuyCard,
    NewRoadmapModal,
    Section,
  },
  data() {
    return {
      formatAddress,
      dispatchStore: useDispatchStore(),
      roadmapStore: useRoadmapStore(),
      userStore: useUserStore(),
      dateFilter: [startOfDay(new Date()), endOfDay(addDays(new Date(), 7))],
      operationPermission: true,
      selectedDays: [],
      collapsedDays: [],
      collapsedRoadmaps: [],
      params: {},
      compact: true,
      isNewRoadmapModalActive: false,
      dayToCreateRoadmap: null,
    };
  },
  computed: {
    ...mapState(useRoadmapStore, ['isFetched']),
    ...mapState(useRoadmapStore, ['isLoading']),
    ...mapState(useRoadmapStore, ['roadmapDays']),
    ...mapState(useRoadmapStore, ['selected']),
    ...mapState(useRoadmapStore, ['roadmaps']),
    roadmapOptions() {
      return {
        fromDate: startOfDay(this.dateFilter[0]),
        toDate: endOfDay(this.dateFilter[1]),
      };
    },
  },
  async mounted() {
    // await this.roadmapStore.find(
    //   { status: true, ...this.roadmapOptions },
    //   this.roadmapOptions,
    // );
    // this.collapsedDays = [...this.roadmapDays];
    this.userStore.find({ isDriver: true }, { key: 'drivers' });
  },
  methods: {
    async handleDisableRoadmap(roadmap) {
      this.$buefy.dialog.confirm({
        title: 'Cancelar hoja de ruta',
        message:
          '¿Estás seguro de <b>cancelar</b> esta hoja de ruta? Esta acción no se puede retroceder',
        confirmText: 'Cancelar',
        cancelText: 'Emm, no',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          await this.roadmapStore.disable(roadmap._id);
        },
      });
    },
    async changeDay(event, roadmap) {
      if (event.added) {
        const updated = await this.dispatchStore.updateDriver(
          event.added.element,
          { roadmap: roadmap._id },
        );
        this.roadmapStore.updateDispatch(updated);
      }
    },
    isOrder,
    isBuy,
    handleCreateRoadmap(day) {
      this.dayToCreateRoadmap = day;
      this.isNewRoadmapModalActive = true;
    },
    logisticByDay(day) {
      return +sum(
        this.getDispatchByDay(day),
        null,
        ({ delivery, withdrawal }) => {
          if (delivery) {
            return (
              delivery.internPrice
              + delivery.partnerPrice
              + delivery.customerPrice
            );
          }
          if (withdrawal) return withdrawal.internPrice + withdrawal.fee;
          return 0;
        },
      );
    },
    isDispatchFromDay(dispatch, day) {
      return (
        (dispatch.withdrawal && dispatch.withdrawal.date === day)
        || (dispatch.delivery && dispatch.delivery.date === day)
      );
    },
    getDispatchWithoutRoadmapByDay(day) {
      return this.getDispatchByDay(day).filter(({ roadmap }) => !roadmap);
    },

    getRoadmapsByDay(day) {
      // eslint-disable-next-line array-callback-return

      return this.roadmaps.filter((roadmap) => {
        const date = new Date(roadmap.date);
        return isSameDay(date, new Date(day));
      });
    },
    getDispatchByDay(day) {
      return this.getRoadmapsByDay(day).flatMap((roadmap) => roadmap.dispatchs);
    },
    getOrdersByDay(day) {
      // eslint-disable-next-line array-callback-return
      return this.getDispatchByDay(day).filter(({ delivery }) => {
        if (delivery) return delivery.date === day;
        return false;
      });
    },
    getBuysByDay(day) {
      // eslint-disable-next-line array-callback-return
      return this.getDispatchByDay(day).filter(({ withdrawal }) => {
        if (withdrawal) return withdrawal.date === day;
        return false;
      });
    },
    handleDeselectAll() {
      this.selectedDays = [];
      this.roadmapStore.deselectAll();
    },
    collapseDay(day) {
      const index = this.collapsedDays.indexOf(day);
      if (index === -1) {
        this.collapsedDays.push(day);
      } else {
        this.collapsedDays.splice(index, 1);
      }
    },
    handleSelectDay(day) {
      if (this.selectedDays.includes(day)) {
        this.roadmapStore.selectDay(day);
      } else {
        this.roadmapStore.deselectDay(day);
      }
    },
    toggleCompact() {
      this.compact = !this.compact;
      this.selectedDays = [];
      this.handleDeselectAll();
    },
    itemsQuantityByDay(day) {
      return this.getDispatchByDay(day).reduce((acc, { cart: { items } }) => {
        // eslint-disable-next-line no-param-reassign
        acc += items.length;
        return acc;
      }, 0);
    },
    async handleChangeDates() {
      await this.roadmapStore.find(this.roadmapOptions);
    },
  },
};
</script>

<style>
.no-focus:focus {
  outline: none;
  box-shadow: none;
}
</style>
