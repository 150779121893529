<template>
  <div>
    <div class="columns" v-if="!isFetched">
      <div class="column is-12">
        <Button :once="true" type="is-primary" :onClickFn="handleFetchCashFlow">Cargar flujos de caja</Button>
      </div>
    </div>
    <div class="columns" v-if="isFetched">
      <div class="column is-12">
        <div class="mb-2">
          <Button pulled="right" type="is-primary" :onClickFn="handleFetchCashFlow" icon="refresh"></Button>
          <!-- <Button
            class="mr-2"
            pulled="right"
            type="is-info"
            :onClickFn="handleToggleView"
            icon="table-headers-eye"
          ></Button> -->
          <ExportCashFlows class="mr-2 is-pulled-right" :flows="sortedFlows" />
        </div>

        <div class="mt-12">
          <div v-if="isGroupedView">
            <p class="title">Flujos de caja</p>
            <CommonCashFlowTable advanced :loading="isLoading" :flows="sortedFlows" @remove="handleFlowRemoved" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';
import CommonCashFlowTable from './CommonCashFlowTable.vue';
import ExportCashFlows from './ExportFlows.vue';

const { CashFlowService } = ApiService;

export default {
  name: 'CashFlow',
  props: {
    advanced: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CommonCashFlowTable,
    ExportCashFlows,
  },
  data() {
    return {
      cashFlow: {},
      isFetched: false,
      isLoading: false,
      isGroupedView: true,
      removedCashFlows: [],
    };
  },
  watch: {
    product() {
      if (!this.product) return;
      this.isFetched = false;
    },
  },
  computed: {
    sortedFlows() {
      return [
        ...this.cashFlow,
      ]
        .filter((flow) => !this.removedCashFlows.includes(flow._id))
        .sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
        );
    },
  },
  methods: {
    handleFlowRemoved(removedFlow) {
      this.removedCashFlows.push(removedFlow._id);
    },
    async fetchCashFlow() {
      this.isLoading = true;
      const { data } = await CashFlowService.find({
        limit: 99999,
      });
      this.isLoading = false;
      this.cashFlow = data.docs;
    },
    async handleFetchCashFlow() {
      await this.fetchCashFlow();
      this.isFetched = true;
    },
    parseConcept(concept) {
      return {
        EGRESS: 'Egreso',
        INGRESS: 'Ingreso',
      }[concept];
    },
    parsePaymentMethod(paymentMethod) {
      return {
        DEFAULT: 'N/A',
        CASH: 'EFECTIVO',
        BANK: 'BANCO',
      }[paymentMethod];
    },
    handleToggleView() {
      this.isGroupedView = !this.isGroupedView;
    },
  },
};
</script>
