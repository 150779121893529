<template>
  <div class="card">
    <div class="card-content">
      <b-field label="Usuario">
        <b-input data-cy="username" v-model="user.username"></b-input>
      </b-field>
      <b-field label="Contraseña">
        <b-input data-cy="password" v-model="user.password" type="password" password-reveal
          @keydown.enter.native="handleLogin"></b-input>
      </b-field>

      <div class="buttons">
        <b-button data-cy="submit" :loading="isLoading" @click="handleLogin" type="is-dark">Login</b-button>
        <b-button type="is-dark" outlined>Olvide Contraseña</b-button>
      </div>
      <div v-if="isCustomerLoginEnabled">
        <hr style="border-top: 3px solid #bbb" />
        <br />
        <div class="buttons is-pulled-right">
          <b-button :loading="isLoading" @click="handleAuthPair" type="is-primary is-light">Ingresar como
            cliente</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      user: {
        username: '',
        password: '',
      },
      isLoading: false,
    };
  },
  methods: {
    async handleLogin() {
      const { username } = this.user;
      if (username === '') {
        return;
      }

      this.isLoading = true;
      await this.$store
        .dispatch('Auth/login', this.user)
        .then(() => {
          this.$router.push('/');
          this.$store.dispatch('Ui/showBars');
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: 'Usuario o contraseña incorrectos',
            position: 'is-bottom',
            type: 'is-danger',
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleAuthPair() {
      this.$router.push('/pair');
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters['Auth/currentUser'] || {};
    },
    isCustomerLoginEnabled() {
      return process.env.VUE_APP_ENV !== 'production';
    },
  },
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
</style>
