<template>
  <div>
    <b-table :paginated="isPaginated" :perPage="perPage" :striped="true" :data="partners" :loading="loading"
      :narrowed="true" @select="handleSelectPartner" :selected.sync="selected"
      :row-class="(row, index) => row.status === false && 'is-danger'">

      <b-table-column field="username" label="Usuario" centered sortable :searchable="advancedTable" v-slot="props">
        {{ props.row.username }}
      </b-table-column>
      <b-table-column field="name" label="Nombre" width="30%" centered sortable :searchable="advancedTable"
        v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column field="role" label="Rol" centered sortable :searchable="advancedTable" v-slot="props">
        {{ props.row.role }}
      </b-table-column>
      <b-table-column width="20%" centered>
        <template v-slot:header="">
          <b-icon icon="barley" />
        </template>
        <template v-slot="props">
          <div class="control">
            <b-taglist attached>
              <b-tooltip label="Agente de venta" type="is-primary is-light">
                <b-tag class="ml-2" type="is-primary is-light">
                  <b-icon icon="handshake" />
                </b-tag>
              </b-tooltip>
              <b-tooltip label="Proveedor" type="is-danger is-light">
                <b-tag class="ml-2" v-if="props.row.isProvider" type="is-danger is-light">
                  <b-icon icon="tractor" />
                </b-tag>
              </b-tooltip>
              <b-tooltip label="Prestamo" type="is-light">
                <b-tag class="ml-2" v-if="props.row.isThird" type="is-light">
                  <b-icon icon="ballot-recount" />
                </b-tag>
              </b-tooltip>
            </b-taglist>
          </div>
        </template>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'PartnerTable',
  props: {
    partners: Array,
    operationPermission: {
      type: Boolean,
      default: false,
    },
    isPaginated: {
      type: Boolean,
      default: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    advancedTable: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {
      selected: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleSelectPartner(partner) {
      this.$emit('select', partner);
    },
  },
  mounted() { },
};
</script>

<style>
tr.is-danger {
  background: #ffffff;
  color: #f14668;
}
</style>
