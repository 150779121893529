<!-- eslint-disable max-len -->
<template>
  <div>
    <CardItem
      ref="cardItem"
      :element="draft"
      :operationPermission="operationPermission"
      :isDraggable="isDraggable"
      :componentClass="[...componentClass]"
      :selected="(customStore && customStore.selected) || selected"
      :isCheckable="isCheckable"
      @select="handleSelect"
    >
      <div class="absolute flex flex-col w-10 gap-2 py-4 my-6 h-1/2 right-4">
        <div v-if="isAbleToDraftToOrder" class="rounded-lg bg-purple-50">
          <div
            @click="handleDraftToOrder(draft)"
            class="w-full h-full cursor-pointer"
          >
            <img
              src="../../../assets/icons/iso.svg"
              alt="activate isotipo"
              class="object-cover rounded-lg"
            />
          </div>
        </div>
        <div v-if="isAbleToConfirm" class="p-2 rounded-lg bg-purple-50">
          <div
            @click="handleConfirmOrder(draft)"
            class="flex items-center justify-center w-full h-full cursor-pointer"
          >
            <b-icon icon="clipboard-plus-outline" type="is-info"></b-icon>
          </div>
        </div>
      </div>

      <div class="flex justify-between space-x-2 text-sm">
        <div class="text-gray-500">{{ draft.date | shortDate }}</div>
        <div>
          <span
            class="p-1.5 text-xs font-medium uppercase tracking-wider rounded-lg bg-opacity-50 text-yellow-800 bg-yellow-200"
            >{{ getStateLabel(draft.state) }}</span
          >
        </div>
        <div>
          <b-dropdown position="is-bottom-left">
            <b-button type="is-text" rounded size="is-small" slot="trigger">
              <b-icon icon="dots-vertical" />
            </b-button>
            <b-dropdown-item @click="handleRemoveDraft(draft)"
              >Eliminar pedido</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
      <div class="grid grid-cols-4">
        <div class="col-span-4 text-sm">
          <a
            v-if="!draft.isSupply && draft.cart.items.some(({ isWholesaler }) => isWholesaler)"
            class="text-xs text-white-700 font-bold bg-yellow-200 hover:underline border-2 border-yellow-500 rounded-md p-0.5 px-1 mr-1"
            @click="handleViewDetails"
            >M</a
          >
          <a
            v-if="draft.isSupply"
            class="text-xs text-white-700 font-bold bg-green-200 hover:underline border-2 border-green-500 rounded-md p-0.5 px-1 mr-1"
            @click="handleViewDetails"
            >A</a
          >
        </div>
      </div>
      <div class="grid grid-cols-1 pb-2 rounded-lg">
        <div>
          <p class="flex items-center pb-2 text-md">
            <span class="flex-grow text-xl font-bold"
              >{{ draft.name }}
              <svg
                v-if="draft.customer"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="inline w-6 h-6 ml-1"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                  clip-rule="evenodd"
                />
              </svg>
              <p v-if="draft.email" class="text-xs font-light">
                {{ draft.email }}
              </p>
            </span>
          </p>

          <p class="flex items-center py-2 text-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="flex-shrink-0 w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
            <span class="flex items-center flex-grow gap-2 ml-2"
              >{{ shortAddress(draft.address) }}  <svg v-if="draft.address.place_id" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="flex-none mr-2"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.6797 8.18168C15.6797 7.61448 15.6293 7.06888 15.5341 6.54568H7.99969V9.64008H12.3053C12.1205 10.6401 11.5565 11.4873 10.7093 12.0545V14.0617H13.2949C14.8069 12.6689 15.6797 10.6177 15.6797 8.18168Z" fill="#4285F4"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C10.16 16 11.9712 15.2832 13.2944 14.0616L10.7096 12.0544C9.9928 12.5344 9.0768 12.8176 8 12.8176C5.9168 12.8176 4.1528 11.4112 3.524 9.51999H0.850403V11.5928C2.1672 14.2072 4.8728 16 8 16Z" fill="#34A853"></path><mask id="mask0_3_1414" maskUnits="userSpaceOnUse" x="0" y="4" width="4" height="8"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.4075H3.52384V11.5931H0V4.4075Z" fill="white"></path></mask><g mask="url(#mask0_3_1414)"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.52379 9.5203C3.36379 9.0395 3.27259 8.5275 3.27259 8.0003C3.27259 7.4723 3.36379 6.9603 3.52379 6.4803V4.4075H0.850986C0.308586 5.4867 -0.000213623 6.7091 -0.000213623 8.0003C-0.000213623 9.2907 0.308586 10.5123 0.850986 11.5931L3.52379 9.5203Z" fill="#FBBC05"></path></g><path fill-rule="evenodd" clip-rule="evenodd" d="M8 3.18169C9.1744 3.18169 10.2288 3.58569 11.0576 4.37849L13.352 2.08329C11.9672 0.792892 10.156 9.15527e-05 8 9.15527e-05C4.8728 9.15527e-05 2.1672 1.79289 0.850403 4.40729L3.524 6.48009C4.1528 4.58889 5.9168 3.18169 8 3.18169Z" fill="#EA4335"></path></svg> </span
            >


          </p>
          <p
            class="flex items-center py-2 text-md"
            v-if="isAdmin && draft.partner"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="flex-shrink-0 w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
              />
            </svg>

            <span class="flex-grow ml-2">{{ draft.partner.username }}</span>
          </p>
          <p class="flex items-center py-2 text-md" v-if="draft.phone">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="flex-shrink-0 w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
              />
            </svg>
            <span class="flex-grow ml-2">{{ draft.phone }}</span>
          </p>
          <p class="flex items-center py-2 text-md" v-if="draft.contactEmail">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="flex-shrink-0 w-6 h-6"
            >
              <path
                fill-rule="evenodd"
                d="M17.834 6.166a8.25 8.25 0 100 11.668.75.75 0 011.06 1.06c-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788 3.807-3.808 9.98-3.808 13.788 0A9.722 9.722 0 0121.75 12c0 .975-.296 1.887-.809 2.571-.514.685-1.28 1.179-2.191 1.179-.904 0-1.666-.487-2.18-1.164a5.25 5.25 0 11-.82-6.26V8.25a.75.75 0 011.5 0V12c0 .682.208 1.27.509 1.671.3.401.659.579.991.579.332 0 .69-.178.991-.579.3-.4.509-.99.509-1.671a8.222 8.222 0 00-2.416-5.834zM15.75 12a3.75 3.75 0 10-7.5 0 3.75 3.75 0 007.5 0z"
                clip-rule="evenodd"
              />
            </svg>

            <span class="flex-grow ml-2">{{ draft.contactEmail }}</span>
          </p>

          <p class="flex items-center py-2 text-md" v-if="draft.payMethod">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="flex-shrink-0 w-6 h-6"
            >
              <path
                d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z"
              />
              <path
                fill-rule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z"
                clip-rule="evenodd"
              />
            </svg>

            <span class="flex-grow ml-2">{{ draft.payMethod }}</span>
          </p>

          <p
            class="flex items-center py-2 text-md"
            v-if="draft.notes && draft.notes.length"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="flex-shrink-0 w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span class="flex-grow ml-2 truncate">{{ draft.notes }}</span>
          </p>
        </div>
      </div>

      <div class="flex items-end justify-between h-full mt-auto space-x-2">
        <button
          class="inline px-2 py-1 text-sm font-medium text-black border-2 border-indigo-400 rounded-lg"
          @click="handleViewDetails"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="inline w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
            />
          </svg>
          <span class="ml-2">{{ draft.cart.items.length }} items</span>
        </button>
        <div class="inline text-xl font-bold text-black align-center">
          ${{ draft.total }}
        </div>
      </div>
    </CardItem>
    <DraftDetails
      :active="isDetailsOpen"
      :draft="draft"
      @dismiss="handleDismissDetails"
    ></DraftDetails>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { formatAddress, shortAddress } from '../../../util/helpers';
import { DRAFT_STATES } from '../../../util/constants';
import ApiService from '../../../services/ApiService';
import DraftDetails from './DraftDetails.vue';
import CardItem from '../../CardItem.vue';
import useDraftStore from '../../../store/draft';

const { ProviderService, OrderService } = ApiService;
// const {
//   PENDING, CONFIRMED, CANCELLED, PREPARED,
// } = ORDER_STATES;

export default {
  name: 'DraftCard',
  props: {
    draft: Object,

    operationPermission: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    isCheckable: {
      type: Boolean,
      default: false,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    showInstance: {
      type: Boolean,
      default: false,
    },
    customStore: {
      type: Object,
      default: null,
    },
    customStoreKey: {
      type: String,
      default: null,
    },
    componentClass: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    DraftDetails,
    CardItem,
  },
  data() {
    return {
      draftStore: useDraftStore(),
      isDetailsOpen: false,
      provider: null,
    };
  },
  computed: {
    isAdmin() {
      return this.currentUser.role === 'ADMIN';
    },
    currentUser() {
      return this.$store.getters['Auth/currentUser'] || {};
    },
    ...mapState(useDraftStore, ['selected']),
    isAbleToDraftToOrder() {
      return this.draft.state === DRAFT_STATES.OPEN && this.isAdmin;
    },
    isAbleToConfirm() {
      return (
        this.provider
        && this.draft.state === DRAFT_STATES.OPEN
        && this.currentUser.isProvider
        && this.draft.cart.items.every(
          ({ product: { provider } }) => provider === this.provider._id,
        )
      );
    },
  },
  mounted() {
    this.fetchProvider();
  },
  methods: {
    shortAddress,
    async fetchProvider() {
      const { data: provider } = await ProviderService.findOne('own');
      this.provider = provider;
      return provider;
    },
    getStateLabel(state) {
      return {
        OPEN: 'BORRADOR',
        CONFIRMED: 'AUTOGESTIÓN',
      }[state];
    },
    handleSelect(isSelected, element) {
      const store = this.customStore || this.draftStore;
      if (isSelected) {
        store.select(element);
      } else {
        store.deselect(element);
      }

      const elements = this.customStoreKey
        ? this.customStore[this.customStoreKey]
        : this.draftStore.drafts;

      const key = this.customStoreKey || 'drafts';

      store.updatePositions(elements, key);
    },
    handleViewDetails() {
      this.isDetailsOpen = true;
    },
    handleDismissDetails() {
      this.isDetailsOpen = false;
    },
    formatAddress,
    async handleRemoveDraft() {
      const { _id: id } = this.draft;
      this.$buefy.dialog.confirm({
        title: 'Eliminar pedido',
        message:
          '¿Estás seguro de <b>eliminar</b> este pedido? Esta acción no se puede retroceder',
        confirmText: 'Eliminar',
        cancelText: 'Emm, no',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          await OrderService.removeDraft(id);
          return this.handleSendUpdateNotification(id);
        },
      });
    },
    handleSendUpdateNotification(id) {
      return this.$emit('update', id);
    },
    handleConfirmOrder() {
      this.$buefy.dialog.confirm({
        title: 'Auto Gestión de Pedido',
        message:
          'Si elegís continuar, vamos a guardar el pedido para que puedas gestionarlos vos mismo',
        confirmText: 'Continuar',
        cancelText: 'Emm, no',
        type: 'is-primary',
        hasIcon: true,
        onConfirm: async () => {
          await this.draftStore.confirm(this.draft._id);
        },
      });
    },
    handleDraftToOrder(draft) {
      this.$buefy.dialog.confirm({
        title: 'Confirmar Pedido en Activate',
        message:
          'Si elegís continuar, el pedido va a entrar dentro del circuito de Activate',
        confirmText: 'Continuar',
        cancelText: 'Emm, no',
        type: 'is-primary',
        hasIcon: true,
        onConfirm: async () => {
          this.$router.push({
            name: 'Cart',
            params: { ...draft, isDraft: true, isNew: true },
          });
        },
      });
    },
  },
  watch: {},
};
</script>

<style>
.checkbox:checked + .check-icon {
  display: flex;
}
</style>
