const store = require('../store/index');

// the array of object to evaluate
// the key of the object to evaluate
// an optional function to apply to the value of the key
const sum = (array, key = null, fn = null, ...args) => array.reduce((acc, curr) => {
  const element = key ? curr[key] : curr;
  const value = fn ? fn(element, ...args) : element;
  return acc + (value || 0);
}, 0);

exports.sum = sum;

const round = (value, decimals = 2) => Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
exports.round = round;

const getDayOfWeek = (value) => {
  if (!value) return '';
  const date = new Date(value);
  return {
    0: 'Domingo',
    1: 'Lunes',
    2: 'Martes',
    3: 'Miercoles',
    4: 'Jueves',
    5: 'Viernes',
    6: 'Sabado',
    7: 'Domingo',
  }[date.getDay()];
};

exports.dayOfWeek = getDayOfWeek;

const capitalize = (value) => {
  if (!value) return '';
  return value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);
};

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );
}

exports.toTitleCase = toTitleCase;

exports.capitalize = capitalize;

exports.omit = (keys, obj) => keys.reduce((a, e) => {
  const { [e]: omit, ...rest } = a;
  return rest;
}, obj);

exports.isNumber = (value) => typeof value === 'number' && Number.isFinite(value);

const isUnity = (unit) => unit === 'UNITY';
exports.isUnity = isUnity;

exports.calculateMixedUnitCost = (
  { product: { unit, unitCost }, quantity },
  { unit: mainUnit },
) => {
  if (unit === 'UNITY') return unitCost * quantity;
  return (unitCost * quantity) / (isUnity(mainUnit) ? 1000 : 100);
};

exports.disconnectedCopy = (object) => JSON.parse(JSON.stringify(object));

exports.depopulate = (properties, obj, key = '_id') => {
  properties.forEach((property) => {
    // eslint-disable-next-line no-param-reassign
    obj[property] = obj[property][key];
  });
  return obj;
};

const friendlyUnity = ({ quantity, product }) => {
  if (!product) return '';
  // eslint-disable-next-line no-param-reassign
  const parsedQuantity = round(quantity);
  return {
    UNITY: `${parsedQuantity} ${parsedQuantity > 1 ? 'u' : 'u'}`,
    WEIGHT: `${
      parsedQuantity >= 1000
        ? `${parsedQuantity / 1000} Kg`
        : `${parsedQuantity} g`
    }`,
  }[product.unit];
};

exports.currency = (value, minimumFractionDigits = 0) => {
  if (!value && value !== 0) return '';

  return round(value).toLocaleString('es-ar', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits,
  });
};

const formatAddress = (address, { state = false } = {}) => `${address.street}${address.unit ? ` ${address.unit}` : ''}${
  address.city ? `, ${address.city}` : ''
}${state ? `, ${address.province}` : ''}`;


exports.formatAddress = formatAddress;

const getTimeRange = (element) => {
  const delivery = element.delivery || element.withdrawal;

  if (delivery.startTime && delivery.endTime) {
    return `${new Date(delivery.startTime).toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true })} - ${new Date(delivery.endTime).toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true })}`;
  }
  return null;
};

exports.createBuyLogisticMessage = (buy) => {
  const buyNumber = `*Pedido:* ${buy.number}`;

  const withdrawalDate = `${
    buy.withdrawal.date
      ? `*Fecha de entrega:* ${getDayOfWeek(buy.withdrawal.date)} ${new Date(
        buy.withdrawal.date,
      ).toLocaleDateString('es')}`
      : ''
  }`;

  const rangeTime = getTimeRange(buy);

  const driver = buy.driver ? `*Repartidor:* ${buy.driver.name}` : null;

  return [
    `Hola ${buy.provider.name}, te confirmamos el horario estimado del retiro de tu pedido:`,
    '',
    buyNumber,
    withdrawalDate,
    rangeTime ? `*Rango horario:* ${rangeTime}` : '',
    driver || '',
    '',
    'Si no podes recibir en el horario, respondénos por acá para buscarle la vuelta.',
    driver && `Si está todo bien, te dejamos en contacto con el repartidor al  ${buy.driver.phone} `,
    '',
    '¡Gracias por elegirnos!',
    '',
    'Cooperativa Activate :)',
  ].join('\n');
};

exports.createLogisticMessage = (order) => {
  const orderNumber = `*Pedido:* ${order.number}`;

  const deliveryAddress = `*Dirección de entrega:* ${formatAddress(
    order.delivery.address,
  )}`;
  const deliveryDate = `${
    order.delivery.date
      ? `*Fecha de entrega:* ${getDayOfWeek(order.delivery.date)} ${new Date(
        order.delivery.date,
      ).toLocaleDateString('es')}`
      : ''
  }`;

  const rangeTime = getTimeRange(order);

  const driver = order.driver ? `*Repartidor:* ${order.driver.name}` : null;

  return [
    `Hola ${order.customer.name.split(' ')[0]}, te confirmamos el horario estimado de entrega de tu pedido:`,
    '',
    orderNumber,
    deliveryAddress,
    deliveryDate,
    rangeTime ? `*Rango horario:* ${rangeTime}` : '',
    driver || '',
    '',
    'Si no podes recibir en el horario, respondénos por acá para buscarle la vuelta.',
    driver && `Si está todo bien, te dejamos en contacto con el repartidor al  ${order.driver.phone} `,
    '',
    '¡Gracias por elegirnos!',
    '',
    'Cooperativa Activate :)',
  ].join('\n');
};



exports.createInvoice = (order, message) => {
  const orderNumber = `*Pedido:* ${order.number}`;
  const customer = `*Nombre:* ${order.customer.name}`;
  const deliveryAddress = `*Dirección de entrega:* ${formatAddress(
    order.delivery.address,
  )}`;
  const deliveryDate = `${
    order.delivery.date
      ? `*Fecha de entrega:* ${getDayOfWeek(order.delivery.date)} ${new Date(
        order.delivery.date,
      ).toLocaleDateString('es')}`
      : ''
  }`;

  const generateItemList = () => order.cart.items.map(
    (item, index) => `• *${friendlyUnity(item)}* ${item.product.name} *$${item.price}* [$${
      item.detail.unitCost
    } x ${friendlyUnity({
      ...item,
      quantity: item.detail.unitQuantity,
    })}]${index !== order.cart.items.length - 1 ? '\n' : ''}`,
  );

  const getDelivery = ({ delivery }) => (!delivery.charged ? 'Sin cargo' : `$${delivery.customerPrice}`);

  const contributions = () => round(
    sum(
      order.cart.items.map((i) => i.detail),
      'contribution',
    ),
  );

  const discountAmount = () => round(contributions() * (order.discount / 100) || 0);

  const hasDiscount = () => discountAmount() > 0;

  const items = `\nツ *Pedido:* \n\n${generateItemList().join('')}`;
  const subtotal = `*Subtotal:* $${order.cart.total}`;
  const delivery = `*Envio:* ${getDelivery(order)}`;
  const discount = hasDiscount() ? `*Descuento:* $${discountAmount()}` : '';
  const total = `*Total:* $${order.total}`;

  const notes = `${order.notes ? `*Notas:* ${order.notes}` : ''}`;

  return [
    deliveryDate,
    '',
    total,
    '',
    '↓ Debajo tenés el detalle de tu pedido y la modalidad de entrega: ↓\n',
    orderNumber,
    customer,
    deliveryAddress,
    items,
    subtotal,
    delivery,
    discount,
    total,
    notes,
    '',
    message,
  ].join('\n');
};

exports.createBuyInvoice = (buy) => {
  const buyNumber = `*Nro Compra:* ${buy.number}`;
  const customer = `*Proveedor:* ${buy.provider.name}`;
  const state = `*Estado:* ${capitalize(buy.state)}`;
  const withdrawalDate = `${
    buy.withdrawal.date
      ? `*Fecha de entrega:* ${getDayOfWeek(buy.withdrawal.date)} ${new Date(
        buy.withdrawal.date,
      ).toLocaleDateString('es')}`
      : ''
  }`;
  const withdrawalName = `*Retira:* ${capitalize(buy.withdrawal.name)}`;
  const providerCost = !buy.withdrawal.charged ? 0 : buy.withdrawal.fee;

  const getWithdrawal = () => (providerCost === 0 ? 'Sin cargo' : `$${providerCost}`);

  const getUnitQuantity = (item) => ({
    UNITY: `[$${item.price / item.quantity} x u]`,
    WEIGHT: `[$${(item.price / item.quantity) * 1000} x Kg]`,
  }[item.product.unit]);

  const generateItemList = () => buy.cart.items.map(
    (item) => `• *[${friendlyUnity(item)}]* ${item.product.name} *$${
      item.price
    }* ${getUnitQuantity(item)} \n`,
  );
  const subtotal = `*Subtotal:* $${buy.cart.total}`;
  const items = `\n*Pedido:* \n\n${generateItemList().join('')}`;
  const withdrawal = `*Logística:* ${getWithdrawal()}`;
  const buyDetail = `*Total:* $${buy.cart.total - providerCost}`;

  return [
    buyNumber,
    withdrawalDate,
    withdrawalName,
    customer,
    state,
    items,
    subtotal,
    withdrawal,
    buyDetail,
  ].join('\n');
};

const getNextWeekDay = (dayOfWeek) => {
  const d = new Date();
  d.setDate(d.getDate() + ((dayOfWeek + 7 - d.getDay()) % 7 || 7));
  return d;
};

exports.getNextDeliveryDates = () => {
  const nextThursday = { date: getNextWeekDay(4), type: 'is-primary' };
  const nextFriday = { date: getNextWeekDay(5), type: 'is-primary' };

  return [nextThursday, nextFriday];
};

const isBuy = (element) => element.provider && !element.delivery && !element.customer;
const isOrder = (element) => element.customer && !element.provider && !element.withdrawal;

exports.isBuy = isBuy;
exports.isOrder = isOrder;

function mapPosition(array, order, key) {
  array.sort((a, b) => {
    const A = a[key];
    const B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    }
    return -1;
  });

  return array;
}

exports.mapPosition = mapPosition;

exports.isPayloadValid = (payload, mandatoryProps) => {
  const errors = mandatoryProps.filter(
    (prop) => !payload[prop.name] || prop.exclude === payload[prop.name],
  );
  if (errors.length) {
    const message = `Verificá los siguientes campos: ${errors
      .map(({ value }) => value)
      .join(', ')}`;
    store.default.dispatch('Ui/errorMessage', { message });
    return false;
  }
  return true;
};

const removeEmpty = (payload) => {
  // eslint-disable-next-line no-param-reassign
  payload = Object.keys(payload).reduce((acc, key) => {
    let element = payload[key];
    if (typeof element === 'object' && element !== null) {
      element = removeEmpty(element);
    }
    if (!element && element !== 0 && element !== false) {
      delete acc[key];
    }
    return acc;
  }, payload);
  return payload;
};

exports.validatePayload = (payload, schema) => {
  // eslint-disable-next-line no-param-reassign
  payload = removeEmpty(payload);
  const { error } = schema.validate(payload, { abortEarly: false, allowUnknown: true });
  if (error) {
    store.default.dispatch('Ui/errorMessage', { message: error.message });
    return false;
  }
  return true;
};

exports.capitalize = (str, lower = true) => (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());

exports.translate = (field, value) => ({
  unit: (() => ({
    WEIGHT: 'PESO',
    UNITY: 'UNIDAD',
  }[value]))(),
  inventory: (() => ({ THEORETICAL: 'TEÓRICO', REAL: 'REAL' }[value]))(),
}[field]);

exports.isEmpty = (value) => value === undefined
  || value === null
  || (typeof value === 'object' && Object.keys(value).length === 0)
  || (typeof value === 'string' && value.trim().length === 0);

exports.parseConcept = (concept) => {
  const concepts = [];
  const levels = concept.split('/');

  if (levels.includes('shortage')) {
    concepts.push('Faltante');
  }
  if (levels.includes('rollback')) {
    concepts.push('Retroceso');
  }
  if (levels.includes('add')) {
    concepts.push('Agregado en');
  }
  if (levels.includes('remove')) {
    concepts.push('Removido en');
  }
  if (levels.includes('prepare')) {
    concepts.push('Preparación');
  }
  if (levels.includes('approve')) {
    concepts.push('Aprobación');
  }
  if (levels.includes('update')) {
    concepts.push('Modificación');
  }
  if (levels.includes('cancel')) {
    concepts.push('Cancelación');
  }
  if (levels.some((level) => level.includes('order'))) {
    concepts.push('Pedido');
  }
  if (levels.includes('buy')) {
    concepts.push('Compra');
  }
  if (levels.includes('adjustment')) {
    concepts.push('Ajuste');
  }

  return concepts.join(' ');
};

exports.shortAddress = (address) => {
  if (!address) return 'Sin dirección';
  return `${address.street}${
    address.unit ? ` ${address.unit}` : ''
  }, ${address.city}`;
};


const isAutomaticInventory = (product) => product.inventoryManagement === 'automatic';
exports.isAutomaticInventory = isAutomaticInventory;

const roundToStep = (value, roundTo) => Math.floor(value / roundTo) * roundTo;

exports.roundToStep = roundToStep;

const getDefaultQuantity = (product) => (product.unit === 'UNITY' ? 1 : 1000);

const getDefaultStep = (product, { isWholesaler = false } = {}) => (isWholesaler ? getDefaultQuantity(product) : product.defaultMargin);


const pendingStock = (product) => {
  const pending = roundToStep(
    Math.floor(
      (product.inventory[0].theoretical || 0) - (product.inventory[0].pending || 0) + (product.inventory[0].committed || 0),
    ),
    getDefaultStep(product),
  );
  return pending;
};
exports.pendingStock = pendingStock;

exports.hasStock = (product) => (isAutomaticInventory(product)
  ? pendingStock(product) > 0
  : !product.isShortage);


exports.ordersByDeliveryAddress = (orders) => {
  const ordersGroupByAddress = orders
    // .filter((order) => order.cart.items.some((item) => item.product.splitPackage))
    .reduce((acc, order) => {
      if (!order.delivery) return acc;

      const addressId = order.delivery.address.label;

      if (!acc[addressId]) {
        acc[addressId] = {
          number: null,
          orders: [],
          partner: null,
          customer: null,
          date: null,
          address: null,
          delivery: null,
        };
      }

      acc[addressId].number = order.number;
      acc[addressId].date = order.delivery.date || [];
      acc[addressId].partner = order.partner || [];
      acc[addressId].customer = order.customer || [];
      acc[addressId].address = order.delivery.address;
      acc[addressId].delivery = order.delivery;
      acc[addressId].orders.push(order);

      return acc;
    }, {});

  return ordersGroupByAddress;
};

exports.getRangeTime = (element) => {
  if (this.getLogistic(element).startTime && this.getLogistic(element).endTime) {
    return `${new Date(this.getLogistic(element).startTime).toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: false })} - ${new Date(this.getLogistic(element).endTime).toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: false })}`;
  }
  return '';
};

exports.shortDate = (value) => {
  if (!value) return '';
  return new Date(value).toLocaleDateString('es');
};


exports.getLogistic = (element) => {
  if (element.delivery) return element.delivery;
  if (element.withdrawal) return element.withdrawal;
  return 0;
};

exports.getDate = (element) => {
  if (element.delivery) {
    if (element.delivery.startTime) {
      return new Date(element.delivery.startTime);
    }
    return new Date(element.delivery.date);
  }
  if (element.withdrawal) {
    if (element.withdrawal.startTime) {
      return new Date(element.withdrawal.startTime);
    }
    return new Date(element.withdrawal.date);
  }
  return 0;
};

exports.dispatchsByDriver = (dispatchs) => dispatchs.reduce((acc, element) => {
  if (element.driver) {
    if (!acc[element.driver.name]) {
      acc[element.driver.name] = [];
    }
    acc[element.driver.name].push(element);
  }
  return acc;
}, {});
