import ApiService from './ApiService';

const { TaxService } = ApiService;

const find = async (params = {}) => TaxService.find(params);

const LOCAL_STORAGE_KEY = 'tax_data';
const EXPIRATION_TIME = 60 * 60 * 1000; // 24 hours in milliseconds

const findWithCache = async (params = {}) => {
  const cached = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (cached) {
    const { data, timestamp } = JSON.parse(cached);
    if (Date.now() - timestamp < EXPIRATION_TIME) {
      return data;
    }
  }

  const { data } = await find(params);
  localStorage.setItem(
    LOCAL_STORAGE_KEY,
    JSON.stringify({
      data,
      timestamp: Date.now(),
    }),
  );
  return data;
};

export default {
  find,
  findWithCache,
};
