<!-- eslint-disable max-len -->
<template>
  <CardItem :element="account">
    <div class="">
      <div class="text-sm">
        <div class="flex flex-col mb-2">
          <p class="text-lg font-bold">
            {{ account.customer.name }}

            <span class="text-xs font-normal lowercase">{{
              operationPermission ? `x ${account.partner.username}` : ''
            }}</span>
          </p>
          <p class="text-xs font-light" v-if="account.customer.email">
            {{ account.customer.email }}
            <svg
              v-if="account.customer.verified"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="inline w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
              />
            </svg>
          </p>
          <p class="text-xs font-light" v-if="!account.customer.email">
            sin correo de autorización
          </p>
        </div>
        <p class="font-bold capitalize text-md"></p>
        <div class="flex flex-col gap-2 py-2">
          <div class="flex">
            <span class="flex-grow font-bold truncate">Correo de contacto</span>
            <span class="tracking-wide" v-if="account.customer.contactEmail"
              >{{ account.customer.contactEmail }}
            </span>
            <span class="tracking-wide" v-if="!account.customer.contactEmail"
              >Sin correo de contacto
            </span>
          </div>
          <div class="flex">
            <span class="flex-grow font-bold truncate">Calle</span>
            <span
              class="tracking-wide"
              v-if="account.customer.address && account.customer.address.street"
              >{{ account.customer.address.street }}</span
            >
          </div>
          <div class="flex">
            <span class="flex-grow font-bold truncate">Ciudad</span>
            <span
              class="tracking-wide"
              v-if="account.customer.address && account.customer.address.city"
              >{{ account.customer.address.city }}</span
            >
          </div>
          <div class="flex">
            <span class="flex-grow font-bold truncate">Província</span>
            <span
              class="tracking-wide"
              v-if="
                account.customer.address && account.customer.address.province
              "
              >{{ account.customer.address.province }}</span
            >
          </div>
          <div class="flex">
            <span class="flex-grow font-bold truncate">Telefono</span>
            <span class="tracking-wide">{{ account.customer.phone }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-end justify-between h-full space-x-2 text-sm">
      <button
        @click="handleEditElement(account.customer)"
        class="inline text-sm font-medium text-black align-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 border-2 rounded-lg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
          />
        </svg>
      </button>
      <!-- <button
        @click="handleSelectElement(account)"
        class="inline text-sm font-medium text-black align-center"
      >
        <b-icon
          class="w-6 h-6 text-gray-700 border-2 rounded-lg"
          icon="cash-register"
        ></b-icon>
      </button> -->

      <button
        @click="handleSendMessage(account.customer)"
        class="inline text-sm font-medium text-black align-center"
      >
        <img
          class="w-6 h-6 border-2 rounded-lg"
          :src="require('../../../assets/whatsapp.svg')"
        />
      </button>

      <div
        @click="handleShowFlows(account)"
        class="inline p-1 font-bold text-black border-2 rounded-lg cursor-pointer text-md align-center"
        :class="account.balance >= 0 ? 'border-green-400 ' : 'border-red-400 '"
      >
        {{ Math.abs(account.balance) | currency }}
      </div>
    </div>

    <!-- <pre>{{ isEditOpen }}</pre> -->
    <UpdateForm
      v-if="account.customer"
      :active="isEditOpen"
      :customer="account.customer"
      :segments="segments"
      @dismiss="isEditOpen = false"
      @update="handleUpdateElement"
    ></UpdateForm>
    <Details
      :active="isDetailsOpen"
      :element="accountSelected"
      @dismiss="handleDismissDetails"
      @payCreated="handleRefreshAccount"
    ></Details>
    <AccountFlows
      :active="isFlowOpen"
      :account="accountSelected"
      @dismiss="handleDismissFlows"
    ></AccountFlows>
  </CardItem>
</template>

<script>
import Details from './AccountDetails.vue';
import AccountFlows from './AccountFlows.vue';
import UpdateForm from '../UpdateForm.vue';
import useAccountStore from '../../../store/account';
import CardItem from '../../CardItem.vue';

export default {
  name: 'AccountCard',
  props: {
    account: { type: Object },
    loading: Boolean,
    operationPermission: {
      type: Boolean,
      default: false,
    },
    segments: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    AccountFlows,
    Details,
    UpdateForm,
    CardItem,
  },
  data() {
    return {
      accountStore: useAccountStore(),
      isEditOpen: false,
      isFlowOpen: false,
      isDetailsOpen: false,
      accountSelected: null,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    handleSelectElement(account) {
      this.accountSelected = account;
      this.isDetailsOpen = true;
    },
    handleEditElement(account) {
      this.accountSelected = account;
      this.isEditOpen = true;
    },
    handleShowFlows(account) {
      this.accountSelected = account;
      this.isFlowOpen = true;
    },
    handleRefreshAccount(pay, account) {
      this.accountStore.update({
        partner: account.partner._id || account.partner,
        customer: account.customer._id,
      });
    },
    handleDismissDetails() {
      this.isDetailsOpen = false;
      this.accountSelected = null;
    },
    handleDismissFlows() {
      this.isFlowOpen = false;
      this.accountSelected = null;
    },
    handleUpdateElement(element) {
      this.accountStore.updateElement(element, 'accounts');
    },
    handleSendMessage(customer) {
      window.open(`https://wa.me/${customer.phone}`, '_blank').focus();
    },
    handleCopyPhone(phone) {
      this.$buefy.dialog.confirm({
        message: `${phone}`,
        cancelText: 'Volver',
        confirmText: 'Abrir en WhatsApp',
        onConfirm: () => {
          window.open(`https://wa.me/${phone}`, '_blank').focus();
        },
      });
    },
  },
  watch: {},
};
</script>
