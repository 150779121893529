<template>
  <div>
    <Section>
      <div class="columns">
        <div class="column is-3 is-offset-9">
          <b-datepicker :mobile-native="false" position="is-bottom-left" placeholder="Fecha de entrega" locale="es-ES"
            v-model="dateFilter" range :nearby-selectable-month-days="true" @input="handleChangeDates">
            <button class="button is-warning" @click="
              dateFilter = [new Date(), new Date()];
            handleChangeDates();
            ">
              <b-icon icon="calendar-today"></b-icon>
              <span>Hoy</span>
            </button>

            <button class="mt-1 button is-danger is-pulled-right" @click="dateFilter = []">
              <b-icon icon="close"></b-icon>
              <span>Borrar filtro</span>
            </button>
          </b-datepicker>
        </div>
      </div>
      <SearchBar :store="dispatchStore" :arrayParams="[this.orderOptions, this.buyOptions]"
        placeholder="✨ Buscá clientes y direcciones"></SearchBar>
      <Layout v-if="isFetched.due && due.length">
        <List :loading="isLoading.due">
          <p class="px-2 text-2xl font-bold">
            {{ `⚠️ Vencidos - ${due.length} envíos` }}
          </p>

          <div v-for="dispatch in due" :key="dispatch._id">
            <template>
              <OrderCard v-if="isOrder(dispatch)" :order="dispatch" :operationPermission="true" :isDraggable="false"
                :isCheckable="false" :compact="compact" :showInstance="true" :customStore="dispatchStore"
                customStoreKey="due"></OrderCard>
              <BuyCard v-if="isBuy(dispatch)" :buy="dispatch" :operationPermission="true" :isDraggable="false"
                :isCheckable="false" :compact="compact" :showInstance="true" :customStore="dispatchStore"
                customStoreKey="due"></BuyCard>
            </template>
          </div>
        </List>
      </Layout>
      <div v-if="isFetched.dispatchs">
        <div class="flex flex-row-reverse gap-2">
          <b-button class="is-primary is-light" @click="toggleCompact()">
            Vista {{ compact ? 'Compacta' : 'Normal' }}
          </b-button>

          <b-field>
            <b-radio-button v-model="isOwnLogistic" :native-value="false" type="is-danger is-light is-outlined">
              <b-icon icon="store"></b-icon>
              <span>Depósito</span>
            </b-radio-button>

            <b-radio-button v-model="isOwnLogistic" :native-value="true" type="is-primary is-light is-outlined">
              <b-icon icon="truck"></b-icon>
              <span>Logística</span>
            </b-radio-button>
          </b-field>
        </div>

        <div v-for="(day, index) of dispatchDays" :key="day" :id="day">
          <div class="relative p-2 px-4 my-2 rounded-lg shadow cursor-pointer bg-purple-50" @click="collapseDay(day)">
            <button class="absolute top-2 right-5 no-focus" type="is-text" size="is-small">
              <b-icon :icon="collapsedDays.includes(day) ? 'chevron-up' : 'chevron-down'
                " />
            </button>
            <b-checkbox v-if="operationPermission" class="absolute p-0 -right-3 top-10" v-model="selectedDays"
              :native-value="day" type="is-primary" size="is-large" @input="handleSelectDay(day, index)"></b-checkbox>

            <div class="grid items-center">
              <div class="flex flex-col gap-1">
                <p class="text-lg font-bold text-indigo-600">
                  {{ day | completeDate }}
                </p>
                <p class="text-sm">
                  {{ day | shortDate }}
                </p>

                <p class="text-sm">
                  Envíos:
                  <span class="font-bold">
                    {{ getNonCancelledDispatchsByDay(day).length }}</span>
                </p>
                <p class="text-sm">
                  Pedidos:
                  <span class="font-bold">
                    {{ getNonCancelledOrdersByDay(day).length }}</span>
                </p>
                <p class="text-sm">
                  Compras:
                  <span class="font-bold">
                    {{ getNonCancelledBuysByDay(day).length }}</span>
                </p>
                <div class="flex items-center gap-2 ">
                  <div class="flex items-center w-32 gap-2 p-1 border-2 rounded-lg" :class="getDispatchsWithDriverByDay(day).length ===
                    getDispatchByDay(day).length
                    ? 'bg-green-500 text-white'
                    : 'bg-red-500 text-white'
                    ">
                    <b-icon icon="truck"></b-icon>
                    {{ getDispatchsWithDriverByDay(day).length }} /
                    {{ getDispatchByDay(day).length }}
                    <b-icon :icon="getDispatchsWithDriverByDay(day).length ===
                      getDispatchByDay(day).length
                      ? 'check'
                      : 'block-helper'
                      "></b-icon>
                  </div>

                  <div class="flex items-center w-32 gap-2 p-1 border-2 rounded-lg" :class="getDispatchsWithRangeTimeByDay(day).length ===
                    getDispatchByDay(day).length
                    ? 'bg-green-500 text-white'
                    : 'bg-red-500 text-white'
                    ">
                    <b-icon icon="clock"></b-icon>
                    {{ getDispatchsWithRangeTimeByDay(day).length }} /
                    {{ getDispatchByDay(day).length }}
                    <b-icon :icon="getDispatchsWithRangeTimeByDay(day).length ===
                      getDispatchByDay(day).length
                      ? 'check'
                      : 'block-helper'
                      "></b-icon>
                  </div>
                </div>
                <b-progress :type="getDeliveredDispatchByDay(day).length ===
                  getNonCancelledDispatchsByDay(day).length
                  ? 'is-success'
                  : 'is-warning'
                  " :value="getDeliveredDispatchByDay(day).length" size="is-medium"
                  :max="getNonCancelledDispatchsByDay(day).length" show-value class="w-64 my-0">
                  <span v-if="getDeliveredDispatchByDay(day).length !==
                    getNonCancelledDispatchsByDay(day).length
                  ">{{ getDeliveredDispatchByDay(day).length }} entregado{{
              getDeliveredDispatchByDay(day).length > 1 ? 's' : ''
                    }}
                    de
                    {{ getNonCancelledDispatchsByDay(day).length }} envíos</span>
                  <span v-if="getNonCancelledDispatchsByDay(day).length &&
                    getDeliveredDispatchByDay(day).length ===
                    getNonCancelledDispatchsByDay(day).length
                  ">Todos los envíos fueron entregados</span>
                  <span v-if="!getNonCancelledDispatchsByDay(day).length">No hay envíos para este día</span>
                </b-progress>
                <p class="text-sm">
                  Total de Logística:
                  <span class="font-bold">
                    {{ logisticByDay(day) | currency(0) }}</span>
                </p>

                <!-- <button
                  v-show="!collapsedDays.includes(day)"
                  class="w-64 px-2.5 py-1.5 text-sm text-white bg-purple-500 rounded-full"
                  @click.stop="handleCreateRoadmap(day)"
                >
                  Nueva hoja de ruta
                </button> -->
              </div>
            </div>
          </div>

          <Layout v-show="!collapsedDays.includes(day)">
            <List :loading="isLoading.dispatchs">
              <div v-for="dispatch in getDispatchByDay(day)" :key="dispatch._id">
                <template>
                  <OrderCard v-if="isOrder(dispatch)" :order="dispatch" :operationPermission="true" :isDraggable="false"
                    :isCheckable="true" :compact="compact" :showInstance="true" :customStore="dispatchStore"
                    customStoreKey="dispatchs"></OrderCard>
                  <BuyCard v-if="isBuy(dispatch)" :buy="dispatch" :operationPermission="true" :isDraggable="false"
                    :isCheckable="true" :compact="compact" :showInstance="true" :customStore="dispatchStore"
                    customStoreKey="dispatchs"></BuyCard>
                </template>
              </div>
            </List>
          </Layout>
        </div>
      </div>
      <NewRoadmapModal :active="isNewRoadmapModalActive" :date="dayToCreateRoadmap"
        @dismiss="isNewRoadmapModalActive = false"></NewRoadmapModal>
      <OrderActions :selected="selected" :operationPermission="operationPermission" @deselectAll="handleDeselectAll"
        :actions="{
          roadmap: true,
          invoice: false,
          deliveryRoute: true,
          consignment: true,
          export: false,
          prepare: false,
        }"></OrderActions>
    </Section>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import {
  startOfDay, endOfDay, addDays, subDays,
} from 'date-fns';
import NewRoadmapModal from '../components/NewRoadmapModal.vue';
import { ORDER_STATES, BUY_STATES } from '../util/constants';
import { isOrder, isBuy, sum } from '../util/helpers';
/* eslint-disable import/prefer-default-export */
import Section from '../components/Section.vue';
import Layout from '../components/Layout.vue';
import OrderActions from '../components/Order/OrderActions.vue';
import SearchBar from '../components/SearchBar.vue';
import useDispatchStore from '../store/dispatch';
import useDeliveryStore from '../store/delivery';
import useUserStore from '../store/user';
import useRoadmapStore from '../store/roadmap';
import useWithdrawalStore from '../store/withdrawal';
import List from '../components/List.vue';
import OrderCard from '../components/Order/OrderCard.vue';
import BuyCard from '../components/Buy/BuyCard.vue';

export default {
  name: 'Prepare',
  components: {
    OrderActions,
    SearchBar,
    NewRoadmapModal,
    Layout,
    List,
    OrderCard,
    BuyCard,
    Section,
  },
  data() {
    return {
      roadmapStore: useRoadmapStore(),
      userStore: useUserStore(),
      dispatchStore: useDispatchStore(),
      deliveryStore: useDeliveryStore(),
      withdrawalStore: useWithdrawalStore(),
      isDayShortageModalActice: false,
      dateFilter: [startOfDay(new Date()), endOfDay(addDays(new Date(), 7))],
      shortageProducts: [],
      operationPermission: true,
      selectedDays: [],
      collapsedDays: [],
      params: {},
      compact: false,
      isOwnLogistic: true,
      dispatchMethods: [],
      isNewRoadmapModalActive: false,
      dayToCreateRoadmap: null,
    };
  },
  computed: {
    ...mapState(useDispatchStore, ['options']),
    ...mapState(useDispatchStore, ['pagination']),
    ...mapState(useDispatchStore, ['isFetched']),
    ...mapState(useDispatchStore, ['isLoading']),
    ...mapState(useDispatchStore, ['selected']),
    ...mapState(useDispatchStore, ['due']),
    ...mapState(useDispatchStore, ['dispatchDays']),
    ...mapState(useDeliveryStore, ['deliveries']),
    ...mapState(useWithdrawalStore, ['withdrawals']),
    dispatchs: {
      get() {
        return this.dispatchStore.dispatchs.filter(
          ({ delivery, withdrawal }) => {
            if (delivery) {
              return (
                this.dispatchMethods[delivery._id]?.isOwnLogistic
                === this.isOwnLogistic
              );
            }
            if (withdrawal) {
              return (
                this.dispatchMethods[withdrawal._id]?.isOwnLogistic
                === this.isOwnLogistic
              );
            }
            return false;
          },
        );
      },
      set(value) {
        return this.dispatchStore.updatePositions(value, 'dispatchs', {
          storeOrdering: true,
        });
      },
    },
    orderOptions() {
      return {
        state: JSON.stringify([ORDER_STATES.CONFIRMED, ORDER_STATES.PREPARED]),
        fromDate: startOfDay(this.dateFilter[0]),
        toDate: endOfDay(this.dateFilter[1]),
      };
    },
    buyOptions() {
      return {
        state: JSON.stringify([BUY_STATES.PENDING, BUY_STATES.APPROVED]),
        fromDate: startOfDay(this.dateFilter[0]),
        toDate: endOfDay(this.dateFilter[1]),
      };
    },
    dueOrderOptions() {
      return {
        state: JSON.stringify([ORDER_STATES.CONFIRMED, ORDER_STATES.PREPARED]),
        'delivery.delivered': false,
        fromDate: startOfDay(subDays(new Date(), 365)),
        toDate: startOfDay(subDays(new Date(), 1)),
      };
    },
    dueBuyOptions() {
      return {
        state: JSON.stringify([BUY_STATES.PENDING]),
        fromDate: startOfDay(subDays(new Date(), 365)),
        toDate: startOfDay(subDays(new Date(), 1)),
      };
    },
    roadmapOptions() {
      return {
        fromDate: startOfDay(this.dateFilter[0]),
        toDate: endOfDay(this.dateFilter[1]),
      };
    },
  },
  async mounted() {
    this.dispatchStore.setQuery(null);
    await Promise.all([
      this.deliveryStore.find({ status: true }),
      this.withdrawalStore.find({ status: true }),
    ]);
    this.dispatchMethods = [...this.deliveries, ...this.withdrawals].reduce(
      (acc, item) => {
        acc[item._id] = item;
        return acc;
      },
      {},
    );

    await this.dispatchStore.find(this.orderOptions, this.buyOptions);

    this.collapsedDays = [...this.dispatchDays];
    await this.userStore.find({ isDriver: true }, { key: 'drivers' });
    // await this.roadmapStore.find(
    //   { status: true, ...this.roadmapOptions },
    //   this.roadmapOptions,
    // );
    await this.dispatchStore.find(
      this.dueOrderOptions,
      this.dueBuyOptions,
      'due',
    );
  },
  methods: {
    handleCreateRoadmap(day) {
      this.dayToCreateRoadmap = day;
      this.isNewRoadmapModalActive = true;
    },
    isOrder,
    isBuy,
    logisticByDay(day) {
      return +sum(
        this.getNonCancelledDispatchsByDay(day),
        null,
        ({ logistic, delivery, withdrawal }) => {
          if (delivery) {
            return logistic + delivery.internPrice;
          }
          if (withdrawal) return withdrawal.internPrice + withdrawal.fee;
          return 0;
        },
      );
    },
    isDispatchFromDay(dispatch, day) {
      return (
        (dispatch.withdrawal && dispatch.withdrawal.date === day)
        || (dispatch.delivery && dispatch.delivery.date === day)
      );
    },
    getNonCancelledOrdersByDay(day) {
      return this.getDispatchByDay(day).filter((dispatch) => {
        if (isOrder(dispatch)) {
          return dispatch.state !== ORDER_STATES.CANCELLED;
        }

        return false;
      });
    },
    getNonCancelledBuysByDay(day) {
      return this.getDispatchByDay(day).filter((dispatch) => {
        if (isBuy(dispatch)) {
          return dispatch.state !== BUY_STATES.CANCELLED;
        }

        return false;
      });
    },
    getNonCancelledDispatchsByDay(day) {
      return this.getDispatchByDay(day).filter((dispatch) => {
        if (isOrder(dispatch)) {
          return dispatch.state !== ORDER_STATES.CANCELLED;
        }
        if (isBuy(dispatch)) {
          return dispatch.state !== BUY_STATES.CANCELLED;
        }
        return false;
      });
    },
    getDate(element) {
      if (element.delivery) {
        if (element.delivery.startTime) {
          return new Date(element.delivery.startTime);
        }
        return new Date(element.delivery.date);
      }
      if (element.withdrawal) {
        if (element.withdrawal.startTime) {
          return new Date(element.withdrawal.startTime);
        }
        return new Date(element.withdrawal.date);
      }
      return 0;
    },

    getDispatchByDay(day) {
      // eslint-disable-next-line array-callback-return
      return this.dispatchs.filter(({ state }) => state !== 'CANCELADA' && state !== 'CANCELADO')
        .filter(({ delivery, withdrawal }) => {
          if (delivery) return delivery.date === day;
          if (withdrawal) return withdrawal.date === day;
          return false;
        })
        .sort((a, b) => {
          const nameComparison = (a.driver?.name || '').localeCompare(b.driver?.name || '');
          if (nameComparison !== 0) {
            return nameComparison;
          }
          return this.getDate(a) - this.getDate(b);
        });
    },

    getDispatchsWithRangeTimeByDay(day) {
      // eslint-disable-next-line array-callback-return
      return this.getNonCancelledDispatchsByDay(day).filter(({ delivery, withdrawal }) => {
        if (delivery) return delivery.date === day && delivery.startTime && delivery.endTime;
        if (withdrawal) return withdrawal.date === day && withdrawal.startTime && withdrawal.endTime;
        return false;
      }).sort((a, b) => this.getDate(a) - this.getDate(b));
    },
    getDispatchsWithDriverByDay(day) {
      // eslint-disable-next-line array-callback-return
      return this.getNonCancelledDispatchsByDay(day).filter(({ delivery, withdrawal, driver }) => {
        if (delivery) return delivery.date === day && driver;
        if (withdrawal) return withdrawal.date === day && driver;
        return false;
      }).sort((a, b) => this.getDate(a) - this.getDate(b));
    },
    getDeliveredDispatchByDay(day) {
      // eslint-disable-next-line array-callback-return
      return this.getNonCancelledDispatchsByDay(day).filter(
        ({ delivery, withdrawal, state }) => {
          if (delivery) return delivery.delivered;
          if (withdrawal) return state === BUY_STATES.APPROVED;
          return false;
        },
      );
    },
    getOrdersByDay(day) {
      // eslint-disable-next-line array-callback-return
      return this.getNonCancelledDispatchsByDay(day).filter(({ delivery }) => {
        if (delivery) return delivery.date === day;
        return false;
      });
    },
    getBuysByDay(day) {
      // eslint-disable-next-line array-callback-return
      return this.getNonCancelledDispatchsByDay(day).filter(({ withdrawal }) => {
        if (withdrawal) return withdrawal.date === day;
        return false;
      });
    },
    handleDeselectAll() {
      this.selectedDays = [];
      this.dispatchStore.selected = [];
    },
    collapseDay(day) {
      const index = this.collapsedDays.indexOf(day);
      if (index === -1) {
        this.collapsedDays.push(day);
      } else {
        this.collapsedDays.splice(index, 1);
      }
    },

    handleSelectDay(day) {
      if (this.selectedDays.includes(day)) {
        this.dispatchStore.selectDay(day, {
          dispatchMethods: this.dispatchMethods,
          isOwnLogistic: this.isOwnLogistic,
        });
      } else {
        this.dispatchStore.deselectDay(day, {
          dispatchMethods: this.dispatchMethods,
          isOwnLogistic: this.isOwnLogistic,
        });
      }
    },
    toggleCompact() {
      this.compact = !this.compact;
    },
    async handleChangeDates() {
      await this.dispatchStore.find(this.orderOptions, this.buyOptions);
    },
  },
};
</script>

<style>
.no-focus:focus {
  outline: none;
  box-shadow: none;
}
</style>
