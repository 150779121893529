<template>
  <section>
    <b-modal :active="active" :has-modal-card="true" :can-cancel="['escape', 'outside']" @close="$emit('dismiss')">
      <div class="border-2 border-indigo-400 rounded-lg modal-card">
        <div class="flex flex-row p-2 text-indigo-400 bg-white border-b-2 border-indigo-400"
          @click.stop="$emit('dismiss')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
          <span>Volver</span>
        </div>
        <section class="modal-card-body">
          <b-field v-if="!updated.mixed" :label="`Actualizar pago a productor`">
            <b-numberinput v-model="updated.taxBase" :controls="false" :min="0" step="0.001"></b-numberinput>
            <p class="control">
              <Button pulled="right" type="is-success" :disabled="isSendingRequest"
                :onClickFn="handleUpdate">Guardar</Button>
            </p>
          </b-field>

          <template v-if="!providerPermission">
            <b-field label="Margen Predeterminado">
              <b-numberinput data-cy="defaultMargin" v-model="updated.defaultMargin" :controls="false"
                :min="0"></b-numberinput>
            </b-field>

            <label for="margins"><b>Margenes</b></label>
            <b-table :data="updated.margins" :narrowed="true" :striped="true" :mobile-cards="false">
              <b-table-column field="quantity" :label="updated.unit === 'UNITY' ? 'Unid.' : 'Grs'" :centered="true"
                :numeric="true">
                <template v-slot="props">
                  {{
                    props.row.quantity === 0 ? 'Mayorista' : props.row.quantity
                  }}
                </template>
              </b-table-column>
              <b-table-column field="quantity" label="%" :centered="true" :numeric="true">
                <template v-slot="props">
                  {{ props.row.value }}
                </template>
              </b-table-column>
              <b-table-column :centered="true">
                <template v-slot="props">
                  <button class="is-pulled-right delete is-small" @click="handleRemoveMargin(props.row)"></button>
                </template>
              </b-table-column>
            </b-table>
            <div class="mt-2 columns">
              <div class="column is-12">
                <div v-if="currentRemovedMargins.length">
                  <div v-for="margin in currentRemovedMargins" :key="margin.quantity">
                    <div class="is-pulled-right" @click="handleAddMargin(margin)">
                      <b-icon icon="plus-circle" />
                    </div>

                    <p class="has-text-danger-dark">
                      <b-icon icon="chevron-right" />
                      {{
                        margin.quantity === 0 ? 'Mayorista' : margin.quantity
                      }}
                      {{ updated.unit === 'UNITY' ? 'Unid.' : 'Grs' }} -
                      {{ margin.value }} %
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-2 columns is-centered">
              <div class="column is-two-fifths">
                <b-field label="Cantidad">
                  <b-numberinput v-if="!newMarginIsWholesaler" v-model="margin.quantity" :controls="false"
                    :min="0"></b-numberinput>
                  <b-input v-if="newMarginIsWholesaler" :disabled="true" value="Mayorista"></b-input>
                </b-field>
              </div>
              <div class="column is-two-fifths">
                <b-checkbox v-model="newMarginIsWholesaler" class="is-pulled-right" :rounded="true" :outlined="true"
                  size="is-small" type="is-danger" :left-label="true">{{
                    newMarginIsWholesaler ? 'Mayorista' : 'Minorista'
                  }}</b-checkbox>
                <b-field label="%">
                  <b-numberinput v-model="margin.value" :controls="false" :min="0" step="0.001"></b-numberinput>
                </b-field>
              </div>
              <div class="column is-one-fifth">
                <b-field label="Agregar">
                  <b-button type="is-success is-light" icon-right="plus" @click="handleAddMargin()" />
                </b-field>
              </div>
            </div>

            <!-- Codigo Mio -->
            <label for="taxes"><b>Impuestos</b></label>
            <b-table :data="updated.taxes" :narrowed="true" :striped="true" :mobile-cards="false">
              <b-table-column field="name" :label="'Tipo'" :centered="true">
                <template v-slot="props">
                  {{ props.row.name }}
                </template>
              </b-table-column>
              <b-table-column field="aliquot" label="%" :centered="true" :numeric="true">
                <template v-slot="props">
                  {{ props.row.aliquot }}
                </template>
              </b-table-column>
              <b-table-column :centered="true">
                <template v-slot="props">
                  <button class="is-pulled-right delete is-small" @click="handleRemoveTax(props.row)"></button>
                </template>
              </b-table-column>
            </b-table>
            <div class="mt-2 columns is-centered">
              <div class="column is-two-fifths">
                <b-field label="Tipo">
                  <b-select v-model="taxSelected" :disabled="!updated.status" placeholder="Seleccionar">
                    <option v-for="option in tax" :value="option.name" :key="option.name">
                      {{ option.name }}
                    </option>
                  </b-select>
                </b-field>
              </div>

              <div class="column is-one-fifth">
                <b-field label="Agregar">
                  <b-button type="is-success is-light" icon-right="plus" @click="handleAddTax()" />
                </b-field>
              </div>
            </div>
            <!-- Codigo Mio -->
            <div class="mt-1">
              <Button pulled="right" type="is-success" :disabled="isSendingRequest"
                :onClickFn="handleUpdate">Guardar</Button>
            </div>
          </template>
        </section>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { schemas } from 'activate-schemas';
import TaxService from '../../../services/tax';
import {
  omit, depopulate, sum, validatePayload,
} from '../../../util/helpers';
import { immutableProperties } from '../../../util/constants';

import useProductStore from '../../../store/product';

const {
  product: { updateProductSchema },
} = schemas;


export default {
  name: 'ProductPrice',
  props: {
    product: Object,
    active: Boolean,
    providerPermission: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      productStore: useProductStore(),
      updated: {
        ...this.product,
        options: (this.product && this.product.options) || {},
      },
      currentRemovedMargins: [],
      currentRemovedComponents: [],
      margin: {
        quantity: 0,
        value: 0,
      },
      tax: [],
      taxSelected: '',
      providers: [],
      newMarginIsWholesaler: false,
      isSendingRequest: false,
    };
  },
  computed: {
    percentage() {
      return sum(this.updated.components, 'quantity');
    },

    isPercentage() {
      return this.updated.unit !== 'UNITY';
    },
  },
  watch: {
    product(product) {
      this.updated = { ...product };
      if (!this.updated.options) {
        this.update.options = {};
      }
      this.currentRemovedMargins = [];
    },
  },
  methods: {
    async handleDisable() {
      this.isSendingRequest = true;
      const { _id: id } = this.updated;
      const updated = await this.productStore.disable(id);
      this.$emit('update', updated);
      this.isSendingRequest = false;
    },
    async handleEnable() {
      this.isSendingRequest = true;
      const { _id: id } = this.updated;
      const updated = await this.productStore.enable(id);
      this.$emit('update', updated);
      this.isSendingRequest = false;
    },
    async handleUpdate() {
      if (this.updated.mixed && this.isPercentage && this.percentage !== 100) {
        this.$buefy.snackbar.open(
          'Los componentes no alcanzan el 100% del compuesto',
        );
        return;
      }

      const { _id: id } = this.updated;

      const productClone = JSON.parse(JSON.stringify(this.updated));

      let payload = omit(immutableProperties.product, productClone);

      payload.taxes = payload.taxes.map((tax) => tax._id);
      payload = depopulate(['category', 'provider'], payload);

      payload.components = payload.components.map((component) => {
        // eslint-disable-next-line no-param-reassign, no-underscore-dangle
        component.product = component.product._id;
        return component;
      });
      if (!validatePayload(payload, updateProductSchema)) return;
      const updated = await this.productStore.update(id, payload);

      this.$emit('update', updated);
      this.$emit('dismiss');
    },

    handleAddMargin(removedMargin) {
      if (removedMargin) {
        this.margin = removedMargin;
        if (this.margin.quantity === 0) this.newMarginIsWholesaler = true;
        this.currentRemovedMargins = this.currentRemovedMargins.filter(
          ({ quantity }) => quantity !== removedMargin.quantity,
        );
      }
      const { quantity, value } = this.margin;
      if ((quantity === 0 && !this.newMarginIsWholesaler) || value === 0) {
        this.$buefy.snackbar.open('No es posible agregar margen 0');
        return;
      }

      const duplicated = this.updated.margins.find(
        (margin) => margin.quantity === quantity,
      );

      if (duplicated) {
        this.$buefy.snackbar.open('Ya existe un margen con esa cantidad');
        return;
      }

      if (this.newMarginIsWholesaler) this.margin.quantity = 0;

      this.newMarginIsWholesaler = false;

      this.updated.margins.push(this.margin);
      this.margin = {
        quantity: 0,
        value: 0,
      };
      this.updated.margins.sort((a, b) => a.quantity - b.quantity);
    },

    handleRemoveMargin(margin) {
      this.currentRemovedMargins.push(margin);
      this.updated.margins = this.updated.margins.filter(
        ({ quantity }) => quantity !== margin.quantity,
      );
    },

    handleRemoveTax(tax) {
      this.updated.taxes = this.updated.taxes.filter(
        ({ name }) => name !== tax.name,
      );
    },

    handleAddTax() {
      const taxSelected = this.tax.find((tax) => tax.name === this.taxSelected);

      const duplicated = this.updated.taxes.find(
        (tax) => tax.name === taxSelected.name,
      );

      if (duplicated) {
        this.$buefy.snackbar.open('Ya existe un impuesto con ese nombre');
        return;
      }

      this.updated.taxes.push(taxSelected);
      this.taxSelected = '';
      this.updated.taxes.sort((a, b) => a.aliquot - b.aliquot);
    },
    handleAddComponent(component) {
      // eslint-disable-next-line no-underscore-dangle
      if (component.product._id === this.updated._id) {
        this.$buefy.snackbar.open(
          'Este producto no puede ser parte del compuesto',
        );
        return;
      }
      this.updated.components.push(component);
      // this.updated.components.sort((a, b) => a.quantity - b.quantity);
    },
    handleRemoveComponent(component) {
      this.currentRemovedComponents.push(component);
      this.updated.components = this.updated.components.filter(
        // eslint-disable-next-line no-underscore-dangle
        ({ product }) => product._id !== component.product._id,
      );
    },
  },
  async mounted() {
    const taxes = await TaxService.findWithCache();
    this.tax = taxes;
  },
};
</script>
