<template>
  <section>
    <b-modal
      :active="active"
      :has-modal-card="true"
      :can-cancel="['escape', 'outside']"
      @close="$emit('dismiss')"
    >
      <div class="border-2 border-indigo-400 rounded-lg modal-card">
        <section class="modal-card-body">
          <DraftInfo v-if="draft" :draft="draft" onlyCustomer></DraftInfo>

          <div v-if="isEmailAlreadyAssociated">
            <b-notification
              type="is-primary is-light"
              aria-close-label="Close notification"
              :closable="false"
            >
              <p class="title is-5 has-text-primary">
                Este correo está asociado a un cliente.
              </p>
              <p><b>Nombre:</b> {{ customerAlreadyCreated.name }} </p>
                  <p><b>Correo:</b> {{ customerAlreadyCreated.email }}  <svg
                    v-if="customerAlreadyCreated.verified"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="inline w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                    />
                  </svg></p>

              <p v-if="customerAlreadyCreated && customerAlreadyCreated.address">
                <b>Dirección:</b> {{ shortAddress(customerAlreadyCreated.address) }}
              </p>

              <p class="mt-4 is-size-7 has-text-dark">
                Despues de asociarlo vas a poder modificar la dirección
              </p>
            </b-notification>
          </div>


          <div v-if="!isEmailAlreadyAssociated">
            <b-field label="Email" class="mt-3">
              <b-input v-model="customer.email" type="email"></b-input>
            </b-field>
            <b-field label="Nombre">
              <b-input v-model="customer.name" type="text"></b-input>
            </b-field>


          <b-field label="Telefono"
            ><PhoneInput v-model="customer.phone" :phone="customer.phone" />
          </b-field>
            <AddressInput
              v-model="customer.address"
              :address="customer.address"
            />


            <b-field v-if="segments.length" label="Segmento">
            <b-select
              v-model="customer.segment"
              placeholder="Seleccionar"
            >
              <option
                v-for="segment in segments"
                :value="segment"
                :key="segment._id"
              >
                {{ segment.name }}
              </option>
            </b-select>
          </b-field>
            <b-field label="Correo de contacto" class="mt-3">
              <b-input v-model="customer.contactEmail" type="email"></b-input>
            </b-field>
          <b-field label="Observaciones">
            <b-input
              v-model="customer.notes"
              maxlength="255"
              type="textarea"
            ></b-input>
          </b-field>
          </div>
          <!-- <pre>{{ customer }}</pre> -->
        </section>
        <footer class="modal-card-foot">
          <button class="button" @click="$emit('dismiss')">Cancelar</button>
          <Button
            data-cy="create-customer"
            v-if="!isEmailAlreadyAssociated"
            type="is-success"
            :onClickFn="handleAddCustomer"
            >Aceptar</Button
          >
          <Button
            v-if="isEmailAlreadyAssociated"
            type="is-primary"
            :onClickFn="handleAddAccount"
            >Asociar</Button
          >
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { schemas } from 'activate-schemas';
import AddressInput from '../AddressInput.vue';
import PhoneInput from '../PhoneInput.vue';
import ApiService from '../../services/ApiService';
import { defaultData } from '../../schemas/customer';
import { validatePayload, disconnectedCopy, shortAddress } from '../../util/helpers';
import DraftInfo from '../Cart/DraftInfo.vue';

const {
  customer: { customerSchema: schema },
} = schemas;


const { CustomerService, SegmentService } = ApiService;

export default {
  name: 'NewCustomer',
  components: {
    PhoneInput,
    AddressInput,
    DraftInfo,
  },
  props: {
    active: Boolean,
    draft: Object,

  },
  data() {
    return {
      customer: disconnectedCopy(defaultData),
      margin: {
        quantity: 0,
        value: 0,
      },
      isEmailAlreadyAssociated: false,
      customerAlreadyCreated: null,
      segments: [],
    };
  },
  watch: {
    active() {
      if (!this.active) return;
      if (this.draft) {
        this.customer.phone = this.draft.phone;
        this.customer.contactEmail = this.draft.contactEmail;
        this.customer.name = this.draft.name;
        this.customer.address = this.draft.address;
      }

      if (!this.customer.notes) {
        this.customer.notes = 'MAYORISTA: SI/NO\nPRESUPUESTO: SI/NO\nLOGÍSTICA:\nOBSERVACIONES:';
      }
    },
    // eslint-disable-next-line func-names
    'customer.name': function () {
      if (!this.customer.name) return;
      this.customer.name = this.customer.name
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    // 'draft._id': function () {
    //   // if (!this.draft._id) return;
    //   // this.customer.phone = this.draft.phone;
    //   // this.customer.email = this.draft.email;
    //   // this.customer.contactEmail = this.draft.contactEmail;
    //   // this.customer.address = this.draft.address;
    // },
    'customer.email': async function () {
      if (!this.customer.email) return;
      const { data: pagination } = await CustomerService.find({
        email: this.customer.email.toLowerCase(),
      });
      if (!pagination.docs.length) {
        this.isEmailAlreadyAssociated = false;
      }
      if (pagination.docs.length) {
        this.isEmailAlreadyAssociated = true;
        // eslint-disable-next-line prefer-destructuring
        this.customerAlreadyCreated = pagination.docs[0];
      }
    },
    // 'customer.phone': async function () {
    //   if (!this.customer.phone) return;
    //   const { data: pagination } = await CustomerService.find({
    //     phone: this.customer.phone,
    //   });
    //   if (!pagination.docs.length) {
    //     this.isEmailAlreadyAssociated = false;
    //   }
    //   if (pagination.docs.length) {
    //     this.isEmailAlreadyAssociated = true;
    //     // eslint-disable-next-line prefer-destructuring
    //     this.customerAlreadyCreated = pagination.docs[0];
    //   }
    // },
  },
  computed: {
    currentUser() {
      return this.$store.getters['Auth/currentUser'] || {};
    },
  },
  methods: {
    handleVerifyDraftEmail() {
      console.log(this.draft.contactEmail);
      this.customer.email = this.draft.contactEmail;
    },
    shortAddress,
    async fetchSegments() {
      const { data: segments } = await SegmentService.find();
      this.segments = segments;
    },
    async handleAddCustomer() {
      const payload = disconnectedCopy(this.customer);

      if (!this.customer.segment) {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'Segmento requerido',
          position: 'is-bottom',
          type: 'is-danger',
          queue: false,
        });
        return;
      }

      payload.segment = this.customer.segment._id;
      if (!validatePayload(payload, schema)) return;

      payload.main = this.currentUser._id;

      console.log(payload);
      const { error, data: customer } = await CustomerService.create(payload);

      if (error) {
        console.error(error.data.code);
        return;
      }

      this.$emit('created', { customer });
      this.customer = disconnectedCopy(defaultData);
    },
    async handleAddAccount() {
      this.$emit('created', { customer: this.customerAlreadyCreated });
      this.customer = disconnectedCopy(defaultData);
      this.isEmailAlreadyAssociated = false;
    },
  },
  mounted() {
    this.fetchSegments();
  },
};
</script>
